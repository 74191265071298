import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { getDeviceByQuery } from '../../../services/device.service';
import { getIdToken } from 'firebase/auth';
import { getCenterByQuery, patchCenter } from '../../../services/center.service';
import Spinner from '../../../components/navigation/Spinner';
import { Button, Col, Row, message, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import {
  BarChartOutlined,
  CopyOutlined,
  DesktopOutlined,
  DollarOutlined,
  LinkOutlined,
  TeamOutlined
} from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getAccountByQuery } from '../../../services/user.service';
import { getProgramSessionByQuery } from '../../../services/programsession.service';
import ResumeDashboard from '../../../components/cards/CardResumeDashboard';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';
import { getTransactionByQuery } from '../../../services/transaction.service';
import axios from 'axios';

const DashboardOrganizerHome = (props) => {
  const { user } = useContext(Auth);
  const [devices, setDevices] = useState([]);
  const [center, setCenter] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [programSessions, setProgramSessions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.account) {
      loadCenter();
    }
  }, [user.account]);

  const loadUsers = async (centerId) => {
    const { status, data } = await getAccountByQuery({ center: centerId, roles: 'user', isActive: true }, user.token);
    if (status === 200) {
      setUsers(data.data);
    }
  };

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      setCenter(data.data[0]);
      await loadUsers(data.data[0]._id);
      await loadProgramSessions(data.data[0]._id);
    }
    setLoading(false);
  };

  const loadProgramSessions = async (centerId) => {
    const { status, data } = await getProgramSessionByQuery({ center: centerId }, user.token);
    if (status === 200) {
      setProgramSessions(data.data);
    }
  };

  if (loading) {
    return <Spinner/>;
  }

  return (
    <>
      <Row justify='center' style={{ gap: 15, marginBottom: 25 }}>
        {center?.plan[0] === '1' && (
          <>
            <ResumeDashboard navigate={navigate} link={'/dashboard/users'} title={capitalize(t('users'))} icon={<TeamOutlined />} value={users.length}/>
          </>
        )}
        {/* {center?.plan[1] === '1' && (
          <>
            <ResumeDashboard navigate={navigate} link={'/dashboard/sessions'} title={capitalizeAll(t('activeSessions'))} icon={<BarChartOutlined />} value={programSessions.filter(p => p.isActive).length + '/' + programSessions.length}/>
          </>
        )} */}
      </Row>
      {/* {center?.plan[1] === '1' && (
        <>
          <Row>
            <Col xs = {24} md={12}>
              <Row style={{ marginBottom: 25 }}>
                <a style={{ border: '1px solid', padding: 5 }} href={`${center.machine}/training/${center._id}`} target={'_blank'} rel="noreferrer">
                  {t('mainScreen').toUpperCase()} <LinkOutlined />
                </a>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs = {24} md={12}>
              <b>Change IP:</b><br />
              <Input placeholder={'IP'} value={center.machine} onChange={(e) => {
                setCenter({ ...center, machine: e.target.value });
              }} />
              <Button type={'primary'} onClick={async () => {
                const { status } = await patchCenter(center._id, { machine: center.machine }, user.token);
                if (status === 200) {
                  message.success('IP updated');
                }
              }
              }>Update IP</Button>
              <br />
              <br />
              <Button type={'primary'} onClick={async () => {
                const res = await axios.get(`${center.machine}:3030/updateFront`);
                if (res.status === 200) {
                  message.success('Update sent');
                }
              }
              }>Update Front Server</Button>
              <br />
              <br />
              <Button type={'primary'} onClick={async () => {
                const res = await axios.get(`${center.machine}:3030/updateCiex`);
                if (res.status === 200) {
                  message.success('Update sent');
                }
              }
              }>Update Back Server</Button>
              <br />
              <br />
              <Button type={'primary'} onClick={async () => {
                const res = await axios.get(`${center.machine}:3030/rebootDesktop`);
                if (res.status === 200) {
                  message.success('Reebot sent');
                }
              }
              }>Restart to desktop</Button>
            </Col>
          </Row>
        </>
      )} */}
    </>
  );
};

export default DashboardOrganizerHome;
