import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MenuProvider from 'react-flexible-sliding-menu';
import Login from '../../pages/auth/Login';
import { Provider as AuthProvider } from '../../services/auth/AuthContext';
import NotFound from '../../pages/NotFound';
import MainLayout from '../layout/MainLayout';
import TopMenu from './TopMenu';
import Logout from '../../pages/auth/Logout';
import Session from '../../pages/client/session';
import ClientPrograms from '../../pages/client/session/ClientPrograms';
import Device from '../../pages/client/device';
import ClientExercises from '../../pages/client/device/ClientExercises';
import Redirect from '../../pages/Redirect';
import DashboardHome from '../../pages/dashboard/home';
import Centers from '../../pages/dashboard/centers/Centers';
import CreateCenter from '../../pages/dashboard/centers/CreateCenter';
import CenterDetail from '../../pages/dashboard/centers/CenterDetail';
import Organizers from '../../pages/dashboard/organizers/Organizers';
import CreateOrganizer from '../../pages/dashboard/organizers/CreateOrganizer';
import MyCenter from '../../pages/dashboard/centers/MyCenter';
import Trainers from '../../pages/dashboard/trainers/Trainers';
import CreateTrainer from '../../pages/dashboard/trainers/CreateTrainer';
import Users from '../../pages/dashboard/users/Users';
import CreateUser from '../../pages/dashboard/users/CreateUser';
import Devices from '../../pages/dashboard/devices/Devices';
import CreateDevice from '../../pages/dashboard/devices/CreateDevice';
import Exercises from '../../pages/dashboard/exercises/Exercises';
import CreateExercise from '../../pages/dashboard/exercises/CreateExercise';
import Programs from '../../pages/dashboard/programs/Programs';
import CreateProgram from '../../pages/dashboard/programs/CreateProgram';
import Settings from '../../pages/dashboard/settings/Settings';
import CreateSetting from '../../pages/dashboard/settings/CreateSetting';
import Sessions from '../../pages/dashboard/sessions/Sessions';
import Charts from '../../pages/dashboard/charts/Charts';
import Templates from '../../pages/dashboard/templates/Templates';
import CreateTemplate from '../../pages/dashboard/templates/CreateTemplate';
import Stats from '../../pages/dashboard/stats/Stats';
import StatsDetail from '../../pages/dashboard/stats/StatsDetail';

const RoutesManagement = (props) => {
  const routesList = [{
    name: 'redirect',
    path: '/redirect',
    element: <Redirect/>
  }, {
    name: 'dashboard',
    path: '/dashboard',
    element: <DashboardHome/>
  }, {
    name: 'centers',
    path: 'dashboard/centers',
    element: <Centers/>
  }, {
    name: 'add center',
    path: '/dashboard/centers/add',
    element: <CreateCenter/>
  }, {
    name: 'edit center',
    path: '/dashboard/centers/edit/:id',
    element: <CreateCenter />
  }, {
    name: 'center detail',
    path: 'dashboard/centers/:id',
    element: <CenterDetail/>
  }, {
    name: 'organizers',
    path: 'dashboard/organizers',
    element: <Organizers/>
  }, {
    name: 'add organizer',
    path: '/dashboard/organizers/add',
    element: <CreateOrganizer/>
  }, {
    name: 'edit organizer',
    path: '/dashboard/organizers/edit/:id',
    element: <CreateOrganizer/>
  }, {
    name: 'my center',
    path: '/dashboard/my-center',
    element: <MyCenter/>
  }, {
    name: 'trainers',
    path: 'dashboard/trainers',
    element: <Trainers/>
  }, {
    name: 'add trainer',
    path: '/dashboard/trainers/add',
    element: <CreateTrainer/>
  }, {
    name: 'edit trainer',
    path: '/dashboard/trainers/edit/:id',
    element: <CreateTrainer />
  }, {
    name: 'users',
    path: 'dashboard/users',
    element: <Users/>
  }, {
    name: 'add user',
    path: '/dashboard/users/add',
    element: <CreateUser/>
  }, {
    name: 'edit user',
    path: '/dashboard/users/edit/:id',
    element: <CreateUser />
  }, {
    name: 'devices',
    path: 'dashboard/devices',
    element: <Devices/>
  }, {
    name: 'add device',
    path: '/dashboard/devices/add',
    element: <CreateDevice/>
  }, {
    name: 'edit device',
    path: '/dashboard/devices/edit/:id',
    element: <CreateDevice />
  }, {
    name: 'exercises',
    path: 'dashboard/users/exercises/:account',
    element: <Exercises/>
  }, {
    name: 'add exercise',
    path: '/dashboard/users/exercises/:account/add',
    element: <CreateExercise/>
  }, {
    name: 'stats',
    path: 'dashboard/users/stats/:account',
    element: <Stats/>
  }, {
    name: 'stats detail',
    path: '/dashboard/users/stats/detail/:id',
    element: <StatsDetail/>
  }, {
    name: 'edit exercise',
    path: '/dashboard/users/exercises/edit/:id',
    element: <CreateExercise />
  }, {
    name: 'templates',
    path: 'dashboard/templates',
    element: <Templates/>
  }, {
    name: 'add exercise',
    path: '/dashboard/templates/add',
    element: <CreateTemplate/>
  }, {
    name: 'edit exercise',
    path: '/dashboard/templates/edit/:id',
    element: <CreateTemplate/>
  }, {
    name: 'programs',
    path: '/dashboard/users/programs/:account',
    element: <Programs />
  }, {
    name: 'add program',
    path: '/dashboard/users/programs/:account/add',
    element: <CreateProgram/>
  }, {
    name: 'edit programs',
    path: '/dashboard/users/programs/edit/:id',
    element: <CreateProgram />
  }, {
    name: 'settings',
    path: '/dashboard/users/settings/:account',
    element: <Settings />
  }, {
    name: 'add settings',
    path: '/dashboard/users/settings/:account/add',
    element: <CreateSetting/>
  }, {
    name: 'edit settings',
    path: '/dashboard/users/settings/edit/:id',
    element: <CreateSetting />
  }, {
    name: 'sessions',
    path: '/dashboard/sessions/',
    element: <Sessions />
  }, {
    name: 'charts',
    path: '/dashboard/charts/',
    element: <Charts />
  }];

  const newRoute = (path, element, key) => {
    return (
      <Route key={key} path={path} element={
        <MenuProvider MenuComponent={TopMenu} direction={'right'}>
          <MainLayout>
            <Routes>
              <Route path="/" element={element} />
            </Routes>
          </MainLayout>
        </MenuProvider>
      } />
    );
  };

  return (

    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/device/:center/:device" element={<Device />} />
          <Route path="/device/:center/:device/:account" element={<ClientExercises />} />
          <Route path="/training/:center" element={<Session />} />
          <Route path="/training/:center/:account" element={<ClientPrograms />} />
          {routesList.map((route, key) => {
            return (
              newRoute(route.path, route.element, key)
            );
          })}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>

    </AuthProvider>

  );
};

export default RoutesManagement;
