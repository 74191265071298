import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Button, Select, message, Popconfirm, Radio } from 'antd';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { getCenterByQuery, getCenterById } from '../../../services/center.service';
import Spinner from '../../../components/navigation/Spinner';
import { getExerciseSessionWithExercise } from '../../../services/exercisesession.service';
import { getIdToken } from 'firebase/auth';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Option } from 'antd/es/mentions';
import { capitalize, capitalizeAll } from '../../../utils/utils';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  zoomPlugin
  // ChartDataLabels
);

const StatsDetail = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const [center, setCenter] = useState(null);
  const [session, setSession] = useState(null);
  const { firebaseUser } = useContext(Auth);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('organizer') >= 0) {
        loadSession(params.id);
      }
    }
  }, [user.account]);

  const loadSession = async (id, token) => {
    const { status, data } = await getExerciseSessionWithExercise({ id: id, isActive: true }, token);
    if (status === 200) {
      setSession(data.data[0]);
      setLoading(false);
    }
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        title: {
          display: false,
          text: '.'
        },
        ticks: {
          stepSize: 10
        }
      },
      x: {
        ticks: {
          display: false
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: () => null // or function () { return null; }
        }
      }
    }
  };

  const bothTpl = (data) => {
    console.log('data', data);
    const labels = data.stats.labels;
    const chartRight = {
      labels,
      datasets: [
        {
          id: 1,
          label: 'Derecho',
          borderColor: 'rgba(82,185,41,1)',
          backgroundColor: 'rgba(82,185,41,0.5)',
          fill: false,
          data: data.stats.datasets.right
        }
      ]
    };
    const chartLeft = {
      labels,
      datasets: [
        {
          id: 2,
          label: 'Izquierdo',
          borderColor: 'rgba(41,128,185,1)',
          backgroundColor: 'rgba(41,128,185,0.5)',
          fill: false,
          data: data.stats.datasets.left
        }
      ]
    };
    return <>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <div style={{ width: '50%' }}>
          <h4>Maneral Izquierdo</h4>
          <b>Fuerza máxima</b>: {parseFloat(data.stats.datasets.leftMax).toFixed(2)} N<br/>
          <b>Fuerza promedio</b>: {parseFloat(data.stats.datasets.leftAvg).toFixed(2)} N<br/>
          <b>Fuerza mínima</b>: {parseFloat(data.stats.datasets.leftMin).toFixed(2)} N<br/>
          <div style={{ height: 230, marginTop: 10 }}>
            <Line
              datasetIdKey='id'
              data={chartLeft}
              options={options}
            />
          </div>
        </div>
        <div style={{ width: '50%' }}>
          <h4>Maneral Derecho</h4>
          <b>Fuerza máxima</b>: {parseFloat(data.stats.datasets.rightMax).toFixed(2)} N<br/>
          <b>Fuerza promedio</b>: {parseFloat(data.stats.datasets.rightAvg).toFixed(2)} N<br/>
          <b>Fuerza mínima</b>: {parseFloat(data.stats.datasets.rightMin).toFixed(2)} N<br/>
          <div style={{ height: 230, marginTop: 10 }}>
            <Line
              datasetIdKey='id'
              data={chartRight}
              options={options}
            />
          </div>
        </div>
      </div>
    </>;
  };

  const averageTpl = (data) => {
    const labels = data.stats.labels;
    const chart = {
      labels,
      datasets: [
        {
          id: 1,
          label: 'Media',
          borderColor: 'rgba(255,99,132,1)',
          backgroundColor: 'rgba(255,99,132,0.5)',
          fill: false,
          data: data.stats.datasets.media
        }
      ]
    };
    return <div style={{ width: '100%' }}>
      <h4>Promedio</h4>
      <b>Fuerza máxima</b>: {parseFloat(data.stats.datasets.mediaMax).toFixed(2)} N<br/>
      <b>Fuerza promedio</b>: {parseFloat(data.stats.datasets.mediaAvg).toFixed(2)} N<br/>
      <b>Fuerza mínima</b>: {parseFloat(data.stats.datasets.mediaMin).toFixed(2)} N<br/>
      <div style={{ height: 230, marginTop: 10 }}>
        <Line
          datasetIdKey='id'
          data={chart}
          options={options}
        />
      </div>
    </div>;
  };

  const rightTpl = (data) => {
    const labels = data.stats.labels;
    const chart = {
      labels,
      datasets: [
        {
          id: 1,
          label: 'Derecho',
          borderColor: 'rgba(82,185,41,1)',
          backgroundColor: 'rgba(82,185,41,0.5)',
          fill: false,
          data: data.stats.datasets.right
        }
      ]
    };
    const datasets = data.stats.datasets;
    return <div style={{ width: '100%' }}>
      <h4>Maneral Derecho</h4>
      <b>Fuerza máxima</b>: {parseFloat(datasets.rightMax).toFixed(2)} N<br/>
      <b>Fuerza promedio</b>: {parseFloat(datasets.rightAvg).toFixed(2)} N<br/>
      <b>Fuerza mínima</b>: {parseFloat(datasets.rightMin).toFixed(2)} N<br/>
      <div style={{ height: 230, marginTop: 10 }}>
        <Line
          datasetIdKey='id'
          data={chart}
          options={options}
        />
      </div>
    </div>;
  };

  const leftTpl = (data) => {
    const labels = data.stats.labels;
    const chart = {
      labels,
      datasets: [
        {
          id: 1,
          label: 'Izquierdo',
          borderColor: 'rgba(41,128,185,1)',
          backgroundColor: 'rgba(41,128,185,0.5)',
          fill: false,
          data: data.stats.datasets.left
        }
      ]
    };
    return <div style={{ width: '100%' }}>
      <h4>Maneral Izquierdo</h4>
      <b>Fuerza máxima</b>: {parseFloat(data.stats.datasets.leftMax).toFixed(2)} N<br/>
      <b>Fuerza promedio</b>: {parseFloat(data.stats.datasets.leftAvg).toFixed(2)} N<br/>
      <b>Fuerza mínima</b>: {parseFloat(data.stats.datasets.leftMin).toFixed(2)} N<br/>
      <div style={{ height: 230, marginTop: 10 }}>
        <Line
          datasetIdKey='id'
          data={chart}
          options={options}
        />
      </div>
    </div>;
  };

  if (loading) {
    return <Spinner/>;
  }
  return (
    <div
      style={{
        background: '#fff',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        display: 'flex'
      }}
    >
      <h1>{session.exercise[0].name}</h1>
      <div id="exercise-detail"
        style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
          <div><b>Número de series</b>: {session.exercise[0].setNumber}</div>
          <div><b>Series</b>:<br/> {session.exercise[0].sets.map((set, index) => {
            return <>Serie {index + 1}: {set.time}{'"'} - {set.percent}% <br/></>;
          })}</div>
          <div><b>Sensores</b>: {session.exercise[0].sensors}</div>
          <div><b>Modo</b>: {session.exercise[0].mode}</div>
          <div><b>Respaldo</b>: {session.exercise[0].back}</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
          <div><b>Silla</b>: {session.exercise[0].chair}</div>
          <div><b>Manija Horizontal</b>: {session.exercise[0].handleHoriz}</div>
          <div><b>Manija Vertical</b>: {session.exercise[0].handleVert}</div>
          <div><b>Anteroposterios</b>: {session.exercise[0].ap}</div>
          <div><b>Longitudinal</b>: {session.exercise[0].lg}</div>
          <div><b>Lateromedial</b>: {session.exercise[0].lm}</div>
        </div>
      </div>

      <div id="session-detail" style={{ marginTop: 32 }}>
        <h3>Sesión {moment(session.timestamp.createdAt).format('DD/MM/YYYY HH:mm')}</h3>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {session.sets.map((set, index) => (
            <div key={index} style={{ border: '1px solid #ccc', padding: 8, marginTop: 8, marginBottom: 8 }}>
              <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div><b>Serie {index + 1}</b></div>
                <div><b>Porcentaje</b>: {set.percent}%</div>
                <div><b>Tiempo</b>: {set.time}{'"'}</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {session.sensors === 'both' && bothTpl(session.raw.sets.find(set => set.key === index))}
                {session.sensors === 'average' && averageTpl(session.raw.sets.find(set => set.key === index))}
                {session.sensors === 'right' && rightTpl(session.raw.sets.find(set => set.key === index))}
                {session.sensors === 'left' && leftTpl(session.raw.sets.find(set => set.key === index))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Button type="primary" icon={<ArrowLeftOutlined />} size='medium' onClick={() => navigate('/dashboard/users/stats/' + session.account)} style={{ marginTop: 32 }}>Volver</Button>
    </div>
  );
};
export default StatsDetail;
