import { methodGet, methodPatch, methodPost } from './api/methods';

export const getAllExerciseSessions = (token) => {
  return methodGet('exercisesession/all', '', token);
};

export const getExerciseSessionById = (id, token) => {
  return methodGet('exercisesession/' + id, '', token);
};

export const getExerciseSessionWithExercise = (req, token) => {
  return methodGet('exercisesession/withexercise', req, token);
};

export const getExerciseSessionByQuery = (req, token) => {
  return methodGet('exercisesession', req, token);
};

export const createExerciseSession = (data, token) => {
  return methodPost('exercisesession/create', data, token);
};

export const patchExerciseSession = (id, data, token) => {
  return methodPatch('exercisesession/update?id=' + id, data, token);
};
