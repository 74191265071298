import { methodDelete, methodGet, methodPatch, methodPost } from './api/methods';

export const getAllExercises = (token) => {
  return methodGet('exercise/all', '', token);
};

export const getExerciseById = (id, token) => {
  return methodGet('exercise/' + id, '', token);
};

export const getExerciseByQuery = (req, token) => {
  return methodGet('exercise', req, token);
};

export const createExercise = (data, token) => {
  return methodPost('exercise/create', data, token);
};

export const patchExercise = (id, data, token) => {
  return methodPatch('exercise/update?id=' + id, data, token);
};

export const deleteExercise = (id, token) => {
  return methodDelete('exercise/delete?id=' + id, token);
};

export const getTemplates = (req, token) => {
  return methodGet('exercise/template', req, token);
};
