export default {
  weightBefore: 'Before begin, enter your weight',
  next: 'next',
  weight: 'weight',
  ready: 'ready',
  finish: 'finish',
  weightControl: 'Weight Control',
  progress: 'progress',
  nextDevice: 'Next Device',
  settings: 'settings',
  tulShort: 'TUL Short',
  tulMedium: 'TUL Medium',
  tulLong: 'TUL Long',
  lastSession: 'Last Session',
  noLastSession: 'No Last Session',
  firstTimeExercise: 'This is the first <br/>time user do this exercise. <br/>Please set your settings.',
  reps: 'reps',
  start: 'start',
  time: 'time',
  exerciseCompleted: 'exercise completed',
  ns: 'ns',
  nf: 'nf',
  mt: 'mt',
  ap: 'ap',
  assistedReps: 'assisted reps',
  discountReps: 'discount reps',
  saveResults: 'save results',
  retry: 'retry',
  archive: 'archive',
  login: 'login',
  logout: 'logout',
  home: 'home',
  user: 'user',
  password: 'password',
  signIn: 'sign in',
  signOut: 'sign out',
  enter: 'enter',
  centers: 'centers',
  organizers: 'organizers',
  users: 'users',
  createNewOrganizer: 'create new organizer',
  createNewCenter: 'create new center',
  confirmDeleteOrganizer: 'Are you sure you want to delete this organizer?',
  organizerDeleted: 'Organizer deleted',
  name: 'name',
  email: 'email',
  phone: 'phone',
  nif: 'nif',
  createOrganizer: 'create organizer',
  createCenter: 'create center',
  confirmDeleteCenter: 'Are you sure you want to delete this center?',
  centerDeleted: 'center deleted',
  surname: 'surname',
  birthdate: 'birthdate',
  position: 'position',
  sessions: 'sessions',
  address: 'address',
  cif: 'cif',
  description: 'description',
  plan: 'plan',
  organizer: 'organizer',
  myCenter: 'my center',
  trainers: 'trainers',
  devices: 'devices',
  exercises: 'exercises',
  booking: 'booking',
  activeSessions: 'active sessions',
  earnings: 'earnings',
  mainScreen: 'main screen',
  goToUrl: 'go to url',
  copyUrl: 'URL copied to clipboard',
  settingsCode: 'settings code',
  weightMeasure: 'weight measure',
  highMeasure: 'high measure',
  createNewTrainer: 'create new trainer',
  createTrainer: 'create trainer',
  isRequired: 'is required',
  editOrganizer: 'edit organizer',
  editCenter: 'edit center',
  editTrainer: 'edit trainer',
  confirmDeleteTrainer: 'Are you sure you want to delete this trainer?',
  trainerDeleted: 'trainer deleted',
  no: 'no',
  yes: 'yes',
  createNewUser: 'create new user',
  createUser: 'create user',
  editUser: 'edit user',
  confirmDeleteUser: 'Are you sure you want to delete this user?',
  userDeleted: 'user deleted',
  credits: 'credits',
  edit: 'edit',
  history: 'history',
  programs: 'programs',
  created: 'created',
  createNewHistory: 'create new history record',
  createHistory: 'create history record',
  historyRecord: 'history record\'s',
  editHistory: 'edit history record',
  histories: 'histories',
  upload: 'upload',
  actions: 'actions',
  confirmDeleteHistory: 'Are you sure you want to delete this history record?',
  historyDeleted: 'history record deleted',
  createNewProgram: 'create new program',
  createProgram: 'create program',
  editProgram: 'edit program',
  confirmDeleteProgram: 'Are you sure you want to delete this program?',
  programDeleted: 'program deleted',
  isActive: 'is active',
  active: 'active',
  availableExercices: 'available exercices',
  selectedExercises: 'selected exercises',
  true: 'true',
  false: 'false',
  createNewSetting: 'create new setting',
  createSetting: 'create setting',
  editSetting: 'edit setting',
  confirmDeleteSetting: 'Are you sure you want to delete this setting?',
  settingDeleted: 'setting deleted',
  exercise: 'exercise',
  fiber: 'fiber',
  concentric: 'concentric',
  eccentric: 'eccentric',
  squeeze: 'squeeze',
  createNewDevice: 'create new device',
  createDevice: 'create device',
  editDevice: 'edit device',
  confirmDeleteDevice: 'Are you sure you want to delete this device?',
  deviceDeleted: 'device deleted',
  minWeight: 'min weight',
  maxWeight: 'max weight',
  identifier: 'identifier',
  createNewExercise: 'create new exercise',
  createExercise: 'create exercise',
  editExercise: 'edit exercise',
  confirmDeleteExercise: 'Are you sure you want to delete this exercise?',
  exerciseDeleted: 'exercise deleted',
  bookingData: 'booking data',
  startDate: 'start date',
  endDate: 'end date',
  startTime: 'start time',
  endTime: 'end time',
  queue: 'queue',
  delete: 'delete',
  create: 'create',
  buyCredits: 'buy credits',
  myProfile: 'my profile',
  see: 'see',
  evolution: 'evolution',
  date: 'date',
  free: 'free',
  booked: 'booked',
  hasQueue: 'has queue',
  inQueue: 'in queue',
  full: 'full',
  book: 'book',
  day: 'day',
  editBookingData: 'edit booking data',
  cancelBook: 'cancel book',
  leaveQueue: 'leave queue',
  personsInQueue: 'persons in queue',
  persons: 'persons',
  selectPlan: 'select plan',
  selectOrganizer: 'select organizer',
  noAttachments: 'no attachments',
  attachments: 'attachments',
  noActive: 'no active',
  noExercises: 'no exercises',
  recommendedWeight: 'recommended weight',
  selectExercise: 'select exercise',
  id: 'id',
  center: 'center',
  trainer: 'trainer',
  credit: 'credit',
  buy: 'buy',
  detail: 'detail',
  details: 'details',
  occupied: 'occupied',
  notEnoughCredits: 'not enough credits',
  language: 'language',
  castilian: 'castilian',
  english: 'english',
  assignSlots: 'assign slots',
  templates: 'templates',
  selectTemplateForDay: 'select template for selected day',
  selectTemplate: 'select template',
  setTemplate: 'set template',
  dayHasEvents: 'day has events. Are you sure you want to add this template?',
  removeUserBook: 'Are you sure you want to remove this user from this booking?',
  type: 'type',
  bookings: 'bookings',
  slots: 'slots',
  createNewTemplate: 'create new template',
  createTemplate: 'create template',
  editTemplate: 'edit template',
  confirmDeleteTemplate: 'Are you sure you want to delete this template?',
  templateDeleted: 'template deleted',
  config: 'config',
  back: 'back',
  search: 'search',
  addCredits: 'add credits',
  saveChanges: 'save changes',
  lastSessions: 'last sessions',
  somethingWrong: 'Something went wrong',
  paymentSuccess: 'Payment success',
  paymentNotSuccess: 'Payment could not be processed or unforeseen event has occurred. Please contact us by email at pagos@seifitness.com',
  paymentSuccessMessage: 'Thanks for your purchase! You can now use your credits to book a session.',
  actualCredits: 'available',
  userSlots: 'slots',
  transactions: 'transactions',
  paid: 'paid',
  accessCode: 'access code',
  wrongAccessCode: 'wrong access code',
  booksession: 'service',
  booksessions: 'services',
  createNewBooksession: 'create new service',
  createBooksession: 'create service',
  editBooksession: 'edit service',
  selectTrainer: 'select trainer',
  confirmDeleteBooksession: 'Are you sure you want to delete this service?',
  booksessionDeleted: 'service deleted',
  theme: 'theme',
  selectTheme: 'select theme',
  dark: 'dark',
  light: 'light',
  price: 'price',
  total: 'total',
  transactionOk: 'transaction success',
  transactionError: 'transaction error',
  activeSession: 'active sesion',
  changePassword: 'change password',
  oldPassword: 'old password',
  newPassword: 'new password',
  confirmPassword: 'confirm password',
  passwordChanged: 'password changed',
  passwordsDoNotMatch: 'passwords do not match',
  incorrectPassword: 'incorrect password',
  passwordTooShort: 'password must be at least 6 characters long',
  resetPassword: 'reset password',
  confirmResetPassword: 'Are you sure you want to reset this user\'s password?',
  passwordReset: 'password reseted',
  tilt: 'ROM',
  strength: 'strength',
  strengthTest: 'strength test',
  confirmDeleteRow: 'are you sure you want to delete this row?',
  addRow: 'add row',
  createNewStrengthTest: 'create new strength test',
  createStrengthTest: 'create strength test',
  editStrengthTest: 'edit strength test',
  confirmDeleteStrengthTest: 'are you sure you want to delete this strength test?',
  strengthTestDeleted: 'strength test deleted',
  percent: 'percent',
  noLimit: 'no limit',
  set: 'set',
  sets: 'sets',
  isometric: 'time static',
  hist: 'hist',
  lastSet: 'last set',
  maxExercises10: 'max 10 exercises',
  assignWeight: 'assign weight',
  chair: 'chair',
  backChair: 'back',
  sensors: 'sensors',
  mode: 'mode',
  both: 'both',
  separated: 'separated',
  left: 'left',
  right: 'right',
  tension: 'tension',
  compression: 'compression',
  apost: 'anteroposterior',
  lm: 'lateromedial',
  lg: 'longitudinal',
  handleVert: 'vertical handle',
  handleHoriz: 'horizontal handle',
  exerciseInProgress: 'exercise in progress',
  resume: 'resume',
  displayHighestPeak: 'display highest peak on screen',
  displayLowestPeak: 'display lowest peak on screen',
  displayeLeftSensor: 'display left sensor on screen',
  displayRightSensor: 'display right sensor on screen',
  displayBothSensors: 'display both sensors on screen',
  maxStrength: 'max strength',
  minStrength: 'min strength',
  avgStrength: 'avg strength',
  stats: 'stats'
};
