import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Button, Select, message, Popconfirm, Radio } from 'antd';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { getCenterByQuery, getCenterById } from '../../../services/center.service';
import Spinner from '../../../components/navigation/Spinner';
import { createExercise, getExerciseById, patchExercise } from '../../../services/exercise.service';
import { getIdToken } from 'firebase/auth';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Option } from 'antd/es/mentions';
import { capitalize, capitalizeAll } from '../../../utils/utils';

const CreateTemplate = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const [center, setCenter] = useState(null);
  const [exercise, setExercise] = useState(null);
  const { firebaseUser } = useContext(Auth);
  const [setsNumber, setSetsNumber] = useState(1);
  const [sets, setSets] = useState([
    { key: 0, time: '10', percent: '60' }
  ]);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { TextArea } = Input;
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('organizer') >= 0) {
        loadCenter();
      } else if (user.account.roles.indexOf('trainer') >= 0) {
        loadCenterId();
      }
    }
  }, [user.account]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      setCenter(data.data[0]);
      form.setFieldsValue({
        sensors: 'separated',
        mode: 'compression'
      });
      if (params.id) {
        await loadExercise(params.id, user.token);
      }
      setLoading(false);
    }
  };

  const loadCenterId = async () => {
    const { status, data } = await getCenterById(user.account.center, user.token);
    if (status === 200) {
      setCenter(data.data);
      form.setFieldsValue({
        sensors: 'separated',
        mode: 'compression'
      });
      if (params.id) {
        await loadExercise(params.id, user.token);
      }
      setLoading(false);
    }
  };

  const loadExercise = async (id, token) => {
    const { status, data } = await getExerciseById(id, token);
    if (status === 200) {
      setExercise(data.data);
      fillForm(data.data);
      setLoading(false);
    }
  };

  const handleAddSet = () => {
    if (setsNumber < 15) {
      setSetsNumber(setsNumber + 1);
      setSets([...sets, { key: setsNumber, time: '10', percent: '60' }]);
    }
  };

  const handleRemoveSet = () => {
    if (setsNumber > 1) {
      setSetsNumber(setsNumber - 1);
      sets.pop();
      setSets([...sets]);
    }
  };

  const handleResetSets = () => {
    setSetsNumber(1);
    setSets([]);
  };

  const handleAddSetTime = (key) => {
    const newSets = sets.map((set) => {
      if (set.key === key) {
        set.time = (parseInt(set.time) + 1).toString();
      }
      return set;
    });
    setSets(newSets);
  };

  const handleRemoveSetTime = (key) => {
    const newSets = sets.map((set) => {
      if (set.key === key) {
        if (set.time > 1) {
          set.time = (parseInt(set.time) - 1).toString();
        }
      }
      return set;
    });
    setSets(newSets);
  };

  const handleChangeSetPercent = (key, value) => {
    const newSets = sets.map((set) => {
      if (set.key === key) {
        set.percent = value;
      }
      return set;
    });
    setSets(newSets);
  };

  const renderSet = (set) => {
    return (
      <div>
        <div>
          <h4 style={{ marginBottom: 0, width: '100%', textAlign: 'left' }}>{capitalize(t('set'))} #{set.key + 1}</h4>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div>
            <div style={{ textAlign: 'left' }}>
              {capitalize(t('time'))}:
            </div>
            <Button onClick={() => handleRemoveSetTime(set.key)}>-</Button>
            <span style={{ fontSize: '1.2rem', marginRight: 5, marginLeft: 5 }}>{set.time}{'"'}</span>
            <Button onClick={() => handleAddSetTime(set.key)}>+</Button>
          </div>
          <div>
            <div style={{ textAlign: 'left' }}>
              {capitalize(t('percent'))}:
            </div>
            <Form.Item>
              <Select defaultValue={set.percent}
                onChange={(e) => handleChangeSetPercent(set.key, e)}>
                <Option value={'10'}>10%</Option>
                <Option value={'20'}>20%</Option>
                <Option value={'30'}>30%</Option>
                <Option value={'40'}>40%</Option>
                <Option value={'50'}>50%</Option>
                <Option value={'60'}>60%</Option>
                <Option value={'70'}>70%</Option>
                <Option value={'80'}>80%</Option>
                <Option value={'90'}>90%</Option>
                <Option value={'100'}>100%</Option>
              </Select>
            </Form.Item>
          </div>
        </div>
      </div>

    );
  };

  const fillForm = (exercise) => {
    setSetsNumber(exercise.setNumber || 1);
    setSets(exercise.setNumber ? exercise.sets : [{ key: 0, time: '10', percent: '60' }]);
    form.setFieldsValue({
      name: exercise.name,
      chair: exercise.chair,
      back: exercise.back,
      sensors: exercise.sensors,
      ap: exercise.ap,
      lm: exercise.lm,
      lg: exercise.lg,
      handleVert: exercise.handleVert,
      handleHoriz: exercise.handleHoriz,
      mode: exercise.mode,
      settings: exercise.settings
    });
  };

  console.log('sets', sets);

  const deactivateExercise = async () => {
    let account;
    if (exercise) {
      const { status } = await patchExercise(exercise._id, { isActive: false }, user.token);
      if (status === 200) {
        navigate('/dashboard/templates/');
        message.success(capitalize(t('templateDeleted')));
      }
    }
  };

  const createNewExercise = async (data, token) => {
    const formData = {
      name: data.name,
      chair: data.chair,
      back: data.back,
      sensors: data.sensors,
      ap: data.ap,
      lm: data.lm,
      lg: data.lg,
      handleVert: data.handleVert,
      handleHoriz: data.handleHoriz,
      mode: data.mode,
      sets: sets,
      setNumber: setsNumber,
      settings: data.settings,
      center: center._id
    };
    if (params.id) await patchExercise(params.id, formData, token);
    else await createExercise(formData, token);
    navigate('/dashboard/templates/');
  };

  const correctClave = async e => {
    await createNewExercise(e, user.token);
  };

  if (loading) {
    return <Spinner/>;
  }
  return (
    <div
      style={{
        background: '#fff',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <Form form={form} onFinish={correctClave} style={{
        width: '100%',
        maxWidth: 400
      }}>
        <Form.Item>
          {exercise ? <h1>{capitalize(t('editTemplate'))}</h1> : <h1>{capitalize(t('createTemplate'))}</h1>}
        </Form.Item>
        <Form.Item
          name="name"
          rules={[{
            required: true,
            message: capitalize(t('name')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('name'))}
            name="name"
            defaultValue={exercise ? exercise.name : ''}
            placeholder={capitalize(t('name'))}
          />
        </Form.Item>
        <Form.Item
          name="chair"
          rules={[{
            required: true,
            message: capitalize(t('chair')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('chair'))}
            name="concentric"
            type="number"
            defaultValue={exercise ? exercise.chair : ''}
            placeholder={capitalize(t('chair'))}
          />
        </Form.Item>
        <Form.Item
          name="back"
          rules={[{
            required: true,
            message: capitalize(t('backChair')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('backChair'))}
            name="back"
            type="number"
            defaultValue={exercise ? exercise.back : ''}
            placeholder={capitalize(t('backChair'))}
          />
        </Form.Item>
        <div>{capitalize(t('sensors'))}</div>
        <Form.Item name="sensors">
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="average">{capitalize(t('both'))}</Radio.Button>
            <Radio.Button value="both">{capitalize(t('separated'))}</Radio.Button>
            <Radio.Button value="left">{capitalize(t('left'))}</Radio.Button>
            <Radio.Button value="right">{capitalize(t('right'))}</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <div>{capitalize(t('mode'))}</div>
        <Form.Item name="mode">
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="tension">{capitalize(t('tension'))}</Radio.Button>
            <Radio.Button value="compression">{capitalize(t('compression'))}</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="ap"
          rules={[{
            required: true,
            message: capitalize(t('apost')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('apost'))}
            name="ap"
            type="number"
            defaultValue={exercise ? exercise.ap : ''}
            placeholder={capitalize(t('apost'))}
          />
        </Form.Item>
        <Form.Item
          name="lm"
          rules={[{
            required: true,
            message: capitalize(t('lm')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('lm'))}
            name="lm"
            type="number"
            defaultValue={exercise ? exercise.lm : ''}
            placeholder={capitalize(t('lm'))}
          />
        </Form.Item>
        <Form.Item
          name="lg"
          rules={[{
            required: true,
            message: capitalize(t('lg')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('lg'))}
            name="lg"
            type="number"
            defaultValue={exercise ? exercise.lg : ''}
            placeholder={capitalize(t('lg'))}
          />
        </Form.Item>
        <Form.Item
          name="handleVert"
          rules={[{
            required: true,
            message: capitalize(t('handleVert')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('handleVert'))}
            name="handleVert"
            type="number"
            defaultValue={exercise ? exercise.handleVert : ''}
            placeholder={capitalize(t('handleVert'))}
          />
        </Form.Item>
        <Form.Item
          name="handleHoriz"
          rules={[{
            required: true,
            message: capitalize(t('handleHoriz')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('handleHoriz'))}
            name="handleHoriz"
            type="number"
            defaultValue={exercise ? exercise.handleVert : ''}
            placeholder={capitalize(t('handleHoriz'))}
          />
        </Form.Item>
        <div style={{ textAlign: 'left' }}>
          {capitalize(t('settings'))}:
        </div>
        <Form.Item
          name="settings"
          rules={[{
            required: true,
            message: capitalize(t('settings')) + ' ' + t('isRequired')
          }]}>
          <TextArea
            addonBefore={capitalize(t('settings'))}
            rows={4}
            name="settings"
            defaultValue={exercise ? exercise.settings : ''}
            placeholder={capitalize(t('settings'))}
          />
        </Form.Item>
        <div
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Button onClick={() => handleRemoveSet()}>-</Button>
          <span style={{ fontSize: '1.2rem', marginRight: 5, marginLeft: 5 }}>{setsNumber} {capitalize(t('sets'))}</span>
          <Button onClick={() => handleAddSet()}>+</Button>
        </div>
        {sets.map((set, key) => {
          return renderSet(set, key);
        })}
        <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-between' }}>
          {exercise && (
            <Popconfirm
              title={capitalize(t('confirmDeleteTemplate'))}
              onConfirm={deactivateExercise}
              // onCancel={cancel}
              okText={capitalize(t('yes'))}
              cancelText={capitalize(t('no'))}
            >
              <Form.Item>
                <Button
                  type="primary"
                  danger
                  className="delete-form-button"
                  style={{ marginRight: 10 }}
                  // onClick={deactivateUser}
                >
                  <DeleteOutlined />
                </Button>
              </Form.Item>
            </Popconfirm>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ marginRight: 10 }}
            >
              {exercise ? capitalizeAll(t('editTemplate')) : capitalizeAll(t('createTemplate'))}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default CreateTemplate;
