import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Table } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import '../../../components/layout/layout.css';
import { getAccountById } from '../../../services/user.service';
import { getExerciseByQuery } from '../../../services/exercise.service';
import { getIdToken } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';

const Exercises = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const params = useParams();
  const [exercises, setExercises] = useState(null);
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('organizer') >= 0) {
        loadAccount(params.account);
      }
    }
  }, [user.account]);

  const loadExercises = async (accountId) => {
    const { status, data } = await getExerciseByQuery({ user: accountId, isActive: true }, user.token);
    if (status === 200) {
      data.data.forEach(exercise => {
        exercise.key = exercise._id;
      });
      setExercises(data.data);
      setLoading(false);
    }
  };

  const loadAccount = async (userId) => {
    const { status, data } = await getAccountById(userId, user.token);
    if (status === 200) {
      setAccount(data.data);
      loadExercises(data.data._id);
    }
  };

  // const loadCenter = async () => {
  //   const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
  //   if (status === 200) {
  //     loadExercises(data.data[0]._id);
  //   }
  // };

  // const loadCenterId = async () => {
  //   const { status, data } = await getCenterById(user.account.center, user.token);
  //   if (status === 200) {
  //     loadExercises(data.data._id);
  //   }
  // };

  const columns = [
    {
      title: capitalize(t('exercises')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.name}
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('name')),
      dataIndex: ['name'],
      key: '_id',
      render: (text, record) => record.name,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('chair')),
      dataIndex: 'chair',
      key: 'chair',
      sorter: {
        compare: (a, b) => a.chair.localeCompare(b.chair),
        multiple: 3
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('backChair')),
      dataIndex: 'back',
      key: 'back',
      sorter: {
        compare: (a, b) => a.back.localeCompare(b.back),
        multiple: 2
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('sensors')),
      dataIndex: 'sensors',
      key: 'sensors',
      sorter: {
        compare: (a, b) => a.sensors.localeCompare(b.sensors),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('mode')),
      dataIndex: 'mode',
      key: 'mode',
      sorter: {
        compare: (a, b) => a.mode.localeCompare(b.mode),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('apost')),
      dataIndex: 'ap',
      key: 'ap',
      sorter: {
        compare: (a, b) => a.ap.localeCompare(b.ap),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('lm')),
      dataIndex: 'lm',
      key: 'lm',
      sorter: {
        compare: (a, b) => a.lm.localeCompare(b.lm),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('lg')),
      dataIndex: 'lg',
      key: 'lg',
      sorter: {
        compare: (a, b) => a.lg.localeCompare(b.lg),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('handleVert')),
      dataIndex: 'handleVert',
      key: 'handleVert',
      sorter: {
        compare: (a, b) => a.handleVert.localeCompare(b.handleVert),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('handleHoriz')),
      dataIndex: 'handleHoriz',
      key: 'handleHoriz',
      sorter: {
        compare: (a, b) => a.handleHoriz.localeCompare(b.handleHoriz),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('settings')),
      dataIndex: 'settings',
      key: 'settings',
      sorter: {
        compare: (a, b) => a.settings.localeCompare(b.settings),
        multiple: 6
      },
      responsive: ['sm']
    }
  ];

  function onChange (pagination, filters, sorter, extra) {

  }

  if (loading) {
    return (
      <Spinner/>
    );
  } else {
    return (
      <>
        <Button type="primary" shape="round" icon={<PlusOutlined />} size='large' onClick={() => navigate('add')} style={{ marginBottom: 18 }}>
          {capitalize(t('createNewExercise'))}
        </Button>
        <div>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <b>{account ? account.name + ' ' + account.surname : ''}</b>
        </div>
        <Table columns={columns} dataSource={exercises} onChange={onChange} onRow={(record, rowIndex) => {
          return {
            onClick: event => { navigate('/dashboard/users/exercises/edit/' + record._id); }, // click row
            onDoubleClick: event => {}, // double click row
            onContextMenu: event => {}, // right button click row
            onMouseEnter: event => {}, // mouse enter row
            onMouseLeave: event => {} // mouse leave row
          };
        }} rowClassName={'row-pointer'}
        />

      </>
    );
  };
};

export default Exercises;
