import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Form, Input, Table, Upload } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  ControlOutlined,
  EditOutlined,
  EuroOutlined,
  FolderOpenOutlined,
  PlusOutlined,
  SettingOutlined, UploadOutlined, TrophyOutlined
} from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import { createAccount, getAccountByQuery, patchAccount } from '../../../services/user.service';
import '../../../components/layout/layout.css';
import { getCenterByQuery, getCenterById } from '../../../services/center.service';
import { createUserWithEmailAndPassword, getAuth, getIdToken, signOut } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';

import * as XLSX from 'xlsx';
import { signUpApp } from '../../../services/firebase/firebaseConfig';
import { getAllPrograms } from '../../../services/program.service';

const Users = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [center, setCenter] = useState(null);
  const [programs, setPrograms] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('organizer') >= 0) {
        loadCenter();
      } else if (user.account.roles.indexOf('trainer') >= 0) {
        loadCenterId();
      }
    }
  }, [user.account]);

  useEffect(() => {
    if (users && users.length > 0 && filter !== '' && filter !== null && filter !== undefined) {
      const filtered = users.filter(user => {
        return user.name?.toLowerCase().includes(filter.toLowerCase()) || user.email?.toLowerCase().includes(filter.toLowerCase()) || user.phone?.toLowerCase().includes(filter.toLowerCase()) || user.nif?.toLowerCase().includes(filter.toLowerCase()) || user.surname?.toLowerCase().includes(filter.toLowerCase());
      });
      console.log('filter', filter);
      console.log('filteredUsers', filtered);
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(users);
    }
  }, [filter, users]);

  const loadUsers = async (centerId) => {
    const { status, data } = await getAccountByQuery({ roles: 'user', center: centerId, isActive: true }, user.token);
    if (status === 200) {
      data.data.forEach(user => {
        user.key = user._id;
      });
      setUsers(data.data.reverse());
      // setFilteredUsers(data.data);
      setLoading(false);
    }
  };

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      const programs = await getAllPrograms(user.token);
      setPrograms(programs.data.data);
      loadUsers(data.data[0]._id);
      setCenter(data.data[0]);
    }
  };

  const loadCenterId = async () => {
    const { status, data } = await getCenterById(user.account.center, user.token);
    if (status === 200) {
      const programs = await getAllPrograms(user.token);
      setPrograms(programs.data.data);
      loadUsers(data.data._id);
      setCenter(data.data);
    }
  };

  const columns = [
    {
      title: capitalize(t('user')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.name} {record.surname}
          <br />
          {record.email}
          <br />
          {record.phone}
          <br />
          {record.nif}
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('name')),
      dataIndex: ['name', 'surname'],
      key: '_id',

      render: (text, record) => record.name + ' ' + record.surname,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('email')),
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 3
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('phone')),
      dataIndex: 'phone',
      key: 'phone',
      sorter: {
        compare: (a, b) => a.phone.localeCompare(b.phone),
        multiple: 2
      },
      responsive: ['sm']
    },
    {
      title: t('nif').toUpperCase(),
      dataIndex: 'nif',
      key: 'nif',
      sorter: {
        compare: (a, b) => a.nif.localeCompare(b.nif),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('programs')),
      key: 'programs',
      render: (text, record) => {
        if (!programs || programs.length <= 0) {
          return '0';
        }
        const userPrograms = programs.filter(program => program.account === record._id);
        return userPrograms.length.toString();
      },
      sorter: {
        compare: (a, b) => {
          a.programsLength = programs.filter(program => program.account === a._id).length;
          b.programsLength = programs.filter(program => program.account === b._id).length;
          return a.programsLength - b.programsLength;
        }
      },
      responsive: ['sm']
    }
  ];

  const createNewAccount = async (data, uid) => {
    const formData = {
      name: data.name,
      surname: data.surname,
      email: data.email,
      firebaseUid: uid,
      phone: data.phone,
      roles: 'user',
      nif: data.nif,
      birthdate: data.birthdate,
      credits: data.credits,
      center: center._id
    };
    await createAccount(formData, user.token);
  };

  const createFirebaseAccount = async e => {
    const { email, passwd } = e;
    const auth = getAuth(signUpApp);
    await createUserWithEmailAndPassword(auth, email, passwd)
      .then(result => {
        createNewAccount(e, result.user.uid);
      })
      .catch(error => {
        console.log(error);
      });
    await signOut(auth);
  };

  const uploadExcel = async (e) => {
    e.preventDefault();

    const files = e.target.files; const f = files[0];
    const reader = new FileReader();
    reader.onload = async function (e) {
      const data = e.target.result;
      const readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json */
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const dataParseFiltered = dataParse.splice(0, 1);
      console.log(dataParse);

      // -----------------------------------UPLOAD
      for await (const user of dataParse) {
        const payload = {
          name: user[1],
          surname: user[2] ? user[2] : '',
          email: user[3],
          phone: user[4] !== undefined ? user[4] : '611111111',
          roles: 'user',
          nif: user[8].toString(),
          passwd: user[8].toString(),
          birthdate: '01-01-2000',
          credits: 0,
          center: center._id
        };
        console.log('user', payload);
        await new Promise(resolve => setTimeout(resolve, 2000));
        createFirebaseAccount(payload);
      }
    };
    reader.readAsBinaryString(f);
    // console.log('1');
    // e.preventDefault();
    // console.log('2');
    //
    // const reader = new FileReader();
    // console.log(e.target.files[0]);
    // reader.onload = (e) => {
    //   const data = e.target.result;
    //   const workbook = XLSX.read(data, { type: 'binary' });
    //   const sheet = workbook.Sheets[workbook.SheetNames[0]];
    //   const dataObject = XLSX.utils.sheet_to_json(sheet);
    //   console.log('DATA', dataObject);
    // };
  };

  function onChange (pagination, filters, sorter, extra) {

  }

  if (loading) {
    return (
      <Spinner/>
    );
  } else {
    return (
      <>
        <Button type="primary" shape="round" icon={<PlusOutlined />} size='large' onClick={() => navigate('add')} style={{ marginBottom: 18 }}>
          {capitalize(t('createNewUser'))}
        </Button>
        {/* <input type={'file'} onChange={(e) => {
          uploadExcel(e);
        }} /> */}
        <Input placeholder={capitalize(t('search'))} onChange={(e) => setFilter(e.target.value)} style={{ marginBottom: 18 }} />
        <Table columns={columns} dataSource={filteredUsers} onChange={onChange} onRow={(record, rowIndex) => {
          return {
            onClick: event => {}, // click row
            onDoubleClick: event => {}, // double click row
            onContextMenu: event => {}, // right button click row
            onMouseEnter: event => {}, // mouse enter row
            onMouseLeave: event => {} // mouse leave row
          };
        }} rowClassName={'row-pointer'}
        expandable={{
          // eslint-disable-next-line react/display-name
          expandedRowRender: record => <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', gap: 10 }}>
              {center?.plan[0] === '1' && (
                <>
                  <Button icon={<EditOutlined />} onClick={() => { navigate('edit/' + record._id); }}>{capitalize(t('edit'))}</Button>
                  {/* <Button icon={<FolderOpenOutlined />} onClick={() => { navigate('histories/' + record._id); }}>{capitalize(t('history'))}</Button> */}
                </>
              )}
              {/* <Button icon={<EuroOutlined />} onClick={() => { navigate('credits/' + record._id); }}>{capitalize(t('addCredits'))}</Button> */}
              {center?.plan[1] === '1' && (
                <>
                  {/* <Button icon={<TrophyOutlined />} onClick={() => { navigate('strength/' + record._id); }}>{capitalize(t('strengthTest'))}</Button> */}
                  <Button icon={<TrophyOutlined />} onClick={() => { navigate('stats/' + record._id); }}>{capitalize(t('stats'))}</Button>
                  <Button icon={<ControlOutlined />} onClick={() => { navigate('exercises/' + record._id); }}>{capitalize(t('exercises'))}</Button>
                  {/* <Button icon={<SettingOutlined />} onClick={() => { navigate('settings/' + record._id); }}>{capitalize(t('settings'))}</Button> */}
                </>
              )}
            </div>
          </React.Fragment>,
          rowExpandable: record => record.name !== 'Not Expandable',
          expandRowByClick: true
        }}
        />
        <br />
      </>
    );
  }
};

export default Users;
