import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Table } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import '../../../components/layout/layout.css';
import { getAccountById } from '../../../services/user.service';
import { getExerciseSessionWithExercise } from '../../../services/exercisesession.service';
import { getIdToken } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';
import moment from 'moment';

const Stats = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const params = useParams();
  const [exercises, setExercises] = useState(null);
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('organizer') >= 0) {
        loadAccount(params.account);
      }
    }
  }, [user.account]);

  const loadSessions = async (accountId) => {
    const { status, data } = await getExerciseSessionWithExercise({ account: accountId, isActive: true }, user.token);
    if (status === 200) {
      data.data.forEach(exercise => {
        exercise.key = exercise._id;
      });
      setExercises(data.data.reverse());
      setLoading(false);
    }
  };

  const loadAccount = async (userId) => {
    const { status, data } = await getAccountById(userId, user.token);
    if (status === 200) {
      setAccount(data.data);
      loadSessions(data.data._id);
    }
  };

  // const loadCenter = async () => {
  //   const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
  //   if (status === 200) {
  //     loadExercises(data.data[0]._id);
  //   }
  // };

  // const loadCenterId = async () => {
  //   const { status, data } = await getCenterById(user.account.center, user.token);
  //   if (status === 200) {
  //     loadExercises(data.data._id);
  //   }
  // };

  const columns = [
    {
      title: capitalize(t('session')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.exercise[0].name}
        </React.Fragment>
      ),
      responsive: ['xs']
    },
    {
      title: capitalize(t('name')),
      dataIndex: ['name'],
      key: '_id',
      render: (text, record) => record.exercise[0].name,
      responsive: ['sm']
    },
    {
      title: capitalize(t('date')),
      key: 'date',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {moment(record.timestamp.createdAt).format('DD/MM/YYYY HH:mm')}
        </React.Fragment>
      )
    }
  ];

  function onChange (pagination, filters, sorter, extra) {

  }

  if (loading) {
    return (
      <Spinner/>
    );
  } else {
    return (
      <>
        <div>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <b>{account ? account.name + ' ' + account.surname : ''}</b>
        </div>
        <Table columns={columns} dataSource={exercises} onChange={onChange} onRow={(record, rowIndex) => {
          return {
            onClick: event => { navigate('/dashboard/users/stats/detail/' + record._id); }, // click row
            onDoubleClick: event => {}, // double click row
            onContextMenu: event => {}, // right button click row
            onMouseEnter: event => {}, // mouse enter row
            onMouseLeave: event => {} // mouse leave row
          };
        }} rowClassName={'row-pointer'}
        />

      </>
    );
  };
};

export default Stats;
